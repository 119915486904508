<template>
    <div>




        <div class="form-check">

            <span>
                <img src="/img/mob/Checked.png" />
            </span>
            <label class="form-check-label mx-2 my-1"  >{{ problemName   }}</label>

            <p style="margin-left:25px;"><b>{{kindTos(akt.solution_selected)}}</b></p>
        </div>
  <hr>

    <div style="color:grey" class="my-1"> {{ $t('m.PDefect.Poznámka')}}:</div>


        <div   v-if="akt.Note1" class="mb-3"><nl2br  v-if="akt.Note1" tag="div" :text="akt.Note1 ? akt.Note1 : ''"></nl2br> </div>


    </div>
</template>

<script>
    const moment = require('moment');
    require('moment/locale/cs');
    moment.locale('cs');
    const axios = require("axios");
    axios.defaults.withCredentials = true;

    const soltrans = {
        '1': 'm.PDefect.MotorSePomaluRozbíhá',
        '2': 'm.PDefect.OpačnýSměrOtáčení',
        '3': "m.PDefect.MotorSeZaChoduZahřívá",
        '4': 'm.PDefect.MotorVibruje',
        '5': 'm.PDefect.ŠkrábavéZvuky',
        '6': 'm.PDefect.HlučnýProvoz',
        '7': 'm.PDefect.HorkáLožiska',
        '8': 'm.PDefect.JináZávada',

    }


    export default {
        name: "Defect",
        data() {
            return {
                items:[]
            };
        },
        props:['akt'],
        computed:{
                problemName(){

                    if (this.akt.solution_selected) {

                      var matches = this.akt.solution_selected.match(/Solution([0-9]+)([a-zA-Z]+)/);
                      let c = soltrans[matches[1]]
                      return this.$t(c)
                    }
                    return ""
                }
        },
        methods:{
            df: function (time, format) {
              moment.locale(this.$i18n.locale);
                var a = moment(time);
                return a.format(format ? format : "LLL")
            },
            loadDefects(){
                this.items=[]
                let params={activity_id:  this.akt.id }
                axios.get(process.env.VUE_APP_API_ROOT_URL + `/motor/${this.akt.motor_id}/defect`, {params: params}).then(result => {
                    //console.log('motors res', result.status)
                    if (result.status == 200) {
                        this.items=result.data
                    }
                })
            },

            kindTos(s){
            /*    const trans = {
                    "Solution1A": 'm.PDefect.SnižteZatížení',
                    "Solution1B":'m.PDefect.ZkontrolujteVelikostOdporuUjistěteSeŽeJePoužitKabelODostatečnémPrůřezu',
                    "Solution1C":'m.PDefect.NahraďtePoškozenýRotorNovým',
                    "Solution1D":'m.PDefect.PoužijteSprávnéElektrickéNapájení',


                    "Solution2A": 'm.PDefect.PřehoďteZapojeníNaMotoruNeboVRozvaděči',
                    "Solution2SendMsg": 'm.PDefect.DotazVýrobciMotoru',


                    "Solution3A":'m.PDefect.SnižteZatížení_Zahřívání',
                    "Solution3B":'m.PDefect.ZprůchodněteVětrací',
                    "Solution3C": 'm.PDefect.ZkontrolujteŘádnéZapojení',
                    "Solution3D": 'm.PDefect.MotorMusíBýtPřevinut',
                    "Solution3E":'m.PDefect.VyhledejteVadnéVodičeSpojeATransformátory',
                    "Solution3SendMsg":'m.PDefect.ChceteOdeslatZprávuSDotazemVýrobciMotoru',



                    Solution4A:'m.PDefect.MotorNáležitěVyrovnejte',
                    Solution4B:'m.PDefect.ZvyšteStabilituZákladů',
                    Solution4C:'m.PDefect.ProveďteVyváženíSpojky',
                    Solution4D:'m.PDefect.ProveďteVyváženíPoháněnéhoZařízení',
                    Solution4E:'m.PDefect.ProveďteVýměnuLožisek',
                    Solution4F:'m.PDefect.OpravteMotor',
                    Solution4G:'m.PDefect.VyvažteRotor',
                    Solution4H:'m.PDefect.ProveďteOpětovnéVyváženíSpojkyNeboRotoru',
                    Solution4I:'m.PDefect.PřekontrolujteZdaNedošloKPřerušeníElektrickéhoObvodu',
                    Solution4J:'m.PDefect.PřizpůsobteLožiskaNeboPřidejteVymezovacíPodložky',



                    Solution5A: 'm.PDefect.OpravteMontážVentilátoru',
                    Solution5B: 'm.PDefect.DotáhněteMontážníČepyMotoru',
                    Solution5SendMsg: 'm.PDefect.ChceteOdeslatZprávuSDotazemVýrobciMotoru',


                    "Solution6A": 'm.PDefect.ZkontrolujteAOpravteUsazení',
                "Solution6B": 'm.PDefect.VyvažteRotor_HlučnýProvoz',
                "Solution6SendMsg": 'm.PDefect.DotazVýrobciMotoru',


                 "Solution7A":'m.PDefect.NarovnejtePřípadněVyměňteHřídel',
                 "Solution7B": 'm.PDefect.SnižteNapnutíŘemenů',
                "Solution7C": 'm.PDefect.PřibližteŘemeniciKLožiskůmMotoru',
                "Solution7D": 'm.PDefect.PoužijteVětšíŘemenici',
                "Solution7E": 'm.PDefect.ProveďteOpětovnéVyrovnáníPohonu',

                "Solution7F": 'm.PDefect.ZajistěteNáležitouKvalitu',
                "Solution7G": 'm.PDefect.OdstraňteStarýMazacíTuk',
                "Solution7H":  'm.PDefect.OmezteMnožstvíMazacího',
               "Solution7I": 'm.PDefect.ZkontrolujteSprávnost',
                "Solution7J": 'm.PDefect.ProveďteVýměnuLožiska',
                "Solution7SendMsg": 'm.PDefect.DotazVýrobciMotoru'

                }
*/

                const trans = {

                    "Solution3Q": "m.PDefect.VlastníŘešeníPoruchy",
                    "Solution3A": "m.PDefect.MotorJePřetížen_Zahřívání",
                    "Solution3B": "m.PDefect.OtvoryVKostřeNebo",
                    "Solution3C": "m.PDefect.MožnýVýpadekFázeMotoru",
                    "Solution3D": "m.PDefect.UzemněníCívky",
                    "Solution3E": "m.PDefect.NevyváženéNapětíNaSvorkách",
                    "Solution3SendMsg":'m.PDefect.DotazVýrobciMotoru',

                    "Solution1Q": "m.PDefect.VlastníŘešeníPoruchy",
                    "Solution1A": "m.PDefect.MotorJePřetížen",
                    "Solution1B": "m.PDefect.NízkéNapětíPřiSpuštění",
                    "Solution1C": "m.PDefect.VadnýKlecovýRotor",
                    "Solution1D": "m.PDefect.NedostačujícíNapětí",
                    "Solution1SendMsg": "m.PDefect.DotazVýrobciMotoru",


                    "Solution2Q" :"m.PDefect.VlastníŘešeníPoruchy",
                    "Solution2A": "m.PDefect.ChybnýSledFází",
                    "Solution2SendMsg":"m.PDefect.DotazVýrobciMotoru",

                    "Solution4Q": "m.PDefect.VlastníŘešeníPoruchy",
                    "Solution4A": "m.PDefect.ChybnéVyrovnáníMotoru",
                    "Solution4B": "m.PDefect.NedostatečnéPevnéZáklady",
                    "Solution4C": "m.PDefect.NevyváženáSpojka",
                    "Solution4D": "m.PDefect.NevyváženéPoháněnéZařízení",
                    "Solution4E": "m.PDefect.VadnáLožiska",
                    "Solution4F": "m.PDefect.ChybnéVyrovnáníLožisek",
                    "Solution4G": "m.PDefect.PosunutáVyvažovacíZávaží",
                    "Solution4H": "m.PDefect.NesouladVyváženíRotoru",
                    "Solution4I": "m.PDefect.VícefázovýMotorBěžíNaJednuFázi",
                    "Solution4J": "m.PDefect.PřílišnáAxiálníVůle",
                    "Solution4SendMsg": "m.PDefect.DotazVýrobciMotoru",


                    "Solution5Q":"m.PDefect.VlastníŘešeníPoruchy",
                            "Solution5A":"m.PDefect.VentilátorDrhneOKoncový",
                            "Solution5B":"m.PDefect.UvolněníMotoruZeZákladovéDesky" ,
                            "Solution5SendMsg":"m.PDefect.DotazVýrobciMotoru",



                    "Solution6Q": "m.PDefect.VlastníŘešeníPoruchy",
                    "Solution6A": "m.PDefect.NerovnoměrnáVzduchováMezera",
                    "Solution6B": "m.PDefect.NevyváženýRotor",
                    "Solution6SendMsg": "m.PDefect.DotazVýrobciMotoru",





                    "Solution7Q" : "m.PDefect.VlastníŘešeníPoruchy",
                    "Solution7A" : "m.PDefect.OhnutáNeboPoškozenáHřídel",
                    "Solution7B"   : "m.PDefect.PřílišnýTahŘemenů",
                        "Solution7C"    : "m.PDefect.PřílišnáVzdálenostŘemenicOdOsazeníHřídele",
                        "Solution7D"    : "m.PDefect.PřílišMalýPrůměrŘemenice",
                        "Solution7E"    : "m.PDefect.ChybnéVyrovnání",
                        "Solution7F"    : "m.PDefect.NedostatečnéMnožstvíMazacíhoTuku",
                        "Solution7G"    : "m.PDefect.PoklesKvalityMazacíhoTukuNeboZnečištěníMaziva",
                        "Solution7H"    : "m.PDefect.PřílišnéMnožstvíMaziva",
                        "Solution7I"    : "m.PDefect.PřetíženíLožiska",
                        "Solution7J"    : "m.PDefect.VadnéLožiskovéKuličky",
                        "Solution7SendMsg"    : "m.PDefect.DotazVýrobciMotoru"
            }














                if (trans[s]) return this.$t(trans[s])
                return s
            }




        }
    }
</script>

<style scoped>
 img{ width:15px;}
</style>
