<template>
    <div>
        <div class="form-check">

            <Confirmed :date="akt.StepOneConfirmationTime" id="check1"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check1">{{$t('m.PInstallation.Manipulace')}}</label>
        </div>
        <div class="form-check">

            <Confirmed :date="akt.StepTwoConfirmationTime" id="check2"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check2">{{$t('m.PInstallation.UstaveníMotoru')}}</label>
        </div>
        <div class="form-check">

            <Confirmed :date="akt.StepThreeConfirmationTime" id="check3"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check3">{{$t('m.PInstallation.PřípravaInstalace')}}</label>
        </div>
        <div class="form-check">
            <Confirmed :date="akt.Step4ConfirmationTime" id="check4"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check4">{{$t('m.PInstallation.ElektrickéPřipojení')}}</label>
        </div>
        <div class="form-check">
            <Confirmed :date="akt.Step5ConfirmationTime" id="check5"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check5">{{$t('m.PInstallation.MechanickéPřipojení')}}</label>
        </div>
        <div class="form-check">
            <Confirmed :date="akt.Step6ConfirmationTime" id="check6"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check6">{{$t('m.PInstallation.ZkušebníProvoz')}}
                <!--<a href="#" class="d-block">Test report ></a>    --></label>
        </div>


        <div class="form-check">

            <Confirmed :date="akt.NoteConfirmationTime" id="check7"></Confirmed>
            <label class="form-check-label mx-2 my-1 " >{{$t('m.PMaintance.Ověření')}}</label>
        </div>



        <div class="form-group ml-4 mt-3">
            <div class="form-control form-control-sm h-auto" id="poznamka" rows="5"  v-if="akt.Note1"><nl2br tag="div" :text="akt.Note1 ? akt.Note1 : ''"></nl2br> </div>
        </div>
    </div>
</template>

<script>
    import Confirmed from "./Confirmed";
    export default {
        components: {Confirmed},
        props:['akt'],
        name: "Instalace"
    }
</script>

<style scoped>

</style>
