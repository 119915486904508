
<template>
    <div>
        <div class="form-check">

            <Confirmed :date="akt.StepOneConfirmationTime" id="check1"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PMaintance.Čištění')}}</label>
        </div>




        <div class="form-check">

            <Confirmed :date="akt.StepTwoConfirmationTime" id="check2"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check2" >{{$t('m.PMaintance.Kontrola')}}</label>
        </div>


        <div class="form-check">

            <Confirmed :date="akt.StepThreeConfirmationTime" id="check3"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check3" >{{$t('m.PMaintance.Servis')}}</label>
        </div>


        <div class="form-check">

            <Confirmed :date="akt.NoteConfirmationTime" id="check5"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PMaintance.Ověření')}}</label>
        </div>

        <div class="form-group ml-4 mt-3">
            <div class="form-control form-control-sm h-auto" id="poznamka" rows="5"  v-if="akt.Note1"><nl2br tag="div" :text="akt.Note1 ? akt.Note1 : ''"></nl2br> </div>
        </div>

    </div>
</template>
<script>import Confirmed from "./Confirmed";
    export default {
        name: "Maintance" ,
        components: {Confirmed},
        props:['akt'],
        methods:{
            tvalid(t){
                if (!t) return false;
                if (t=="") return false;
                if (t=="null") return false;
                return true
            }
        }
    }
</script>

<style scoped>

</style>
