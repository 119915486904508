<template>
    <div>
        <!--
        <div class="form-check">
             <Confirmed :date="akt.StepOneConfirmationTime" id="check1"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check1" >{{$t('m.PMeasurement.MěřeníProudů')}}</label>
        </div>

        <div class="d-flex">
            <div>
            {{$t('m.PMeasurement.ProudovýOdběr')}}
            </div>
            <div>
                <p>L1: <span class="btn border-info">{{akt.CurrentL1}} </span> A</p>
                <p>L2: <span class="btn border-info">{{akt.CurrentL2}}</span> A</p>
                <p>L3: <span class="btn border-info">{{akt.CurrentL3}}</span> A </p>
            </div>
        </div>

        <div class="form-group ml-4">
            <div class="form-control form-control-sm" id="poznamka1" rows="5">{{akt.Note1}} </div>
        </div>
-->



        <div class="form-check">
            <Confirmed :date="akt.StepTwoConfirmationTime" id="check2"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check2" >{{$t('m.PMeasurement.MěřeníVibrací')}}</label>
        </div>

      <div class="ml-4"><strong> {{$t('m.PMeasurement.VibraceCelkové')}}</strong></div>

        <div class="d-flex">
            <div class="mt-2 ml-4 " style="width:110px;">


            </div>
            <div>

                <p> <span class="btn border-info">  {{akt.VibrationAxisA }}</span> mm/ss</p>
                <p> <span class="btn border-info"> {{akt.VibrationAxisB }}</span> mm/ss </p>
                <p> <span class="btn border-info"> {{akt.VibrationAxisC }}</span>  mm/ss</p>
<!--
                <p>{{$t('m.PMeasurement.Osa4')}} <span class="btn border-info">  {{akt.VibrationAxisD }}</span> mm/ss</p>
                <p>{{$t('m.PMeasurement.Osa5')}} <span class="btn border-info"> {{akt.VibrationAxisE }}</span> mm/ss </p>
                <p>{{$t('m.PMeasurement.Osa6')}} <span class="btn border-info"> {{akt.VibrationAxisF }}</span>  mm/ss</p>
-->

            </div>
        </div>

      <div class="ml-4"><strong>{{$t('m.PMeasurement.VibraceLožisek')}}</strong></div>

      <div class="d-flex">
            <div class="mt-2 ml-4 " style="width:110px;">

DE
            </div>
            <div>
                <p> <span class="btn border-info">  {{akt.VibrationAxisD }}</span> mm/ss</p>
                <p> <span class="btn border-info"> {{akt.VibrationAxisE }}</span> mm/ss </p>

            </div>
      </div>
      <div class="d-flex">
            <div class="mt-2 ml-4 " style="width:110px;">

    NDE
            </div>
            <div>
                <p> <span class="btn border-info">  {{akt.VibrationAxisF }}</span> mm/ss</p>
                <p> <span class="btn border-info"> {{akt.VibrationAxisG }}</span> mm/ss </p>

            </div>
      </div>





        <div class="form-check">
             <Confirmed :date="akt.StepThreeConfirmationTime" id="check1"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check1" >{{$t('m.PMeasurement.MěřeníTeplot')}}</label>
        </div>
      <div class="ml-4"><strong>{{$t('m.PMeasurement.TeplotaKostry')}}</strong></div>
        <div class="d-flex">
            <div class="mt-2 ml-4" style="width:130px;">

            </div>
            <div>
                <p> <span class="btn border-info">{{akt.TemperatureT1}} </span> &deg;C</p>
            </div>
        </div>

      <div class="ml-4"><strong>{{$t('m.PMeasurement.TeplotaLožisek')}}</strong></div>
        <div class="d-flex">
            <div class="mt-2 ml-4" style="width:130px;">
              <p class="mb-4">DE</p>
              <p class="">NDE</p>
            </div>
            <div>
                <p> <span class="btn border-info">{{akt.TemperatureT2}}</span> &deg;C</p>
                <p> <span class="btn border-info">{{akt.TemperatureT3}}</span>  &deg;C</p>
            </div>
        </div>





        <div class="form-check">

             <Confirmed :date="akt.NoteConfirmationTime" id="check4"></Confirmed>
            <label class="form-check-label mx-2 my-1" for="check4">{{$t('m.PMaintance.Ověření')}}</label>
        </div>

        <div class="form-group ml-4 mt-3">
            <div class="form-control form-control-sm h-auto" id="poznamka" rows="5"  v-if="akt.Note1"><nl2br tag="div" :text="akt.Note1 ? akt.Note1 : ''"></nl2br> </div>
        </div>

    </div>
</template>

<script>
    import Confirmed from "./Confirmed";
    export default {
        name: "Measurement",
        components: {Confirmed},
        props:['akt'],
        methods:{
            tvalid(t){
                if (!t) return false;
                if (t=="") return false;
                if (t=="null") return false;
                return true
            }
        }
    }
</script>

<style scoped>

</style>
