<template>


    <div>



        <div class="form-check">

            <Confirmed :date="akt.NoteConfirmationTime" id="check4"></Confirmed>

            <label class="form-check-label mx-2 my-1" >{{$t('m.PMaintance.Ověření')}}</label>
        </div>


        <div class="form-group ml-4 mt-2">
            <div class="form-control form-control-sm h-auto" id="poznamka" rows="5"  v-if="akt.Note1"><nl2br tag="div" :text="akt.Note1 ? akt.Note1 : ''"></nl2br> </div>
        </div>



    </div>

</template>

<script>
    import Confirmed from "./Confirmed";
    export default {
        name: "Other",
        components: {Confirmed},
        props:['akt'],
        mounted() {

        }


    }
</script>

<style scoped>

</style>
