<template>
    <!-- Activity Modal -->
    <div class="modal fade" id="activityModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header py-2">
                    <h6 class="my-1">{{ activityTypeName(akt)}}</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Zavřít">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col col-4">{{$t('m.PActivityBase.InstalačníTechnik')}}</div>
                        <div class="col col-8">{{akt.userinfo ? akt.userinfo.name : ''}}</div>
                    </div>
                    <div class="row">
                        <div class="col col-4">{{$t('m.PActivityBase.Firma')}}</div>
                        <div class="col col-8">{{akt.userinfo ? akt.userinfo.company : ''}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col col-4">Datum aktivity:</div>
                        <div class="col col-8">{{df(akt.Time,'LL')}}<span class="ml-4">{{df(akt.Time,'LT')}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col col-4">{{$t('motor.kniha.poloha')}}</div>
                        <div class="col col-8">
                                        <span v-if="akt.Latitude &&  akt.Longitude">
                                            <a target="_blank" :href="`https://www.google.com/maps/place/${akt.Latitude},${akt.Longitude}`">{{akt.Latitude}}, {{akt.Longitude}}</a>
                                        </span>
                            <span v-else>
                                            {{ $t('motor.aktivita.poloha-neznama')}}
                                        </span>

                        </div>
                    </div>
                    <hr>
                    <Instalace v-if="akt.ActivityType=='1'" :akt="akt"></Instalace>
                    <Commissioning v-if="akt.ActivityType=='2'" :akt="akt"></Commissioning>
                    <Maintance v-if="akt.ActivityType=='3'" :akt="akt"></Maintance>
                    <Lubrication v-if="akt.ActivityType=='4'" :akt="akt"></Lubrication>
                    <Measurement v-if="akt.ActivityType=='5'" :akt="akt"></Measurement>
                    <Defect ref='defectbody' v-if="akt.ActivityType=='6'" :akt="akt"></Defect>

                    <Other v-if="akt.ActivityType=='8'" :akt="akt"></Other>

                    <div class="row">
                        <!--    <div class="col col-8 col-sm-9 my-1">Status aktivity podle výkonného technika:</div> -->
                        <div class="col col-4 col-sm-3 text-right">
                            <button type="button" data-dismiss="modal" class="btn btn-primary py-0 px-3">OK</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Instalace from "./aktivity/Instalace";
    import Commissioning from "./aktivity/Commissioning";
    import Maintance from "./aktivity/Maintance";
    import Lubrication from "./aktivity/Lubrication";
    import Measurement from "./aktivity/Measurement";
    import Defect from "./aktivity/Defect";
    import Other from "./aktivity/Other";

    const moment = require('moment');
    require('moment/locale/cs');
    moment.locale('cs');
    export default {
        name: "AktivityModal",
        props:['akt'],
        components: { Defect, Measurement, Lubrication, Maintance, Commissioning, Instalace, Other},
        methods:{
            df: function (time, format) {
              moment.locale(this.$i18n.locale);
                if (!time) return '';
                var a = moment(time);
                return a.format(format ? format : "LLL")
            },
            activityTypeName(a){
                /*  {
                  Expedition,
                  Installation,
                  Commissioning,
                  Maintance,
                  Lubrication,
                  Measurement,
                  Defect
              }*/

                var akt = parseInt(a.ActivityType);

                if (akt==0 )return this.$t('m.PExpedition.Expedice')
                if (akt==1 )return this.$t('m.PViewEngine.Instalace')
                if (akt==2 )return this.$t('m.PCommissioning.UvedeníDoProvozu')
                if (akt==3 )return this.$t('m.PViewEngine.PreventivníÚdržba')
                if (akt==4 )return this.$t('m.PViewEngine.PViewEngine_PreventivníMazání')
                if (akt==5 )return this.$t('m.PViewEngine.PViewEngine_PreventivníMěření')
                if (akt==6 )return this.$t('m.PDefect.Porucha')
                if (akt==7 )return this.$t('m.POther.Ostatní')
                if (akt==8 )return this.$t('m.POther.Ostatní')
            },
        }
    }
</script>

<style scoped>

</style>
