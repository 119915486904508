<template>
<span>
    <img src="/img/mob/Checked.png" v-if="tvalid(date)"/>
</span>
</template>

<script>
    export default {
        name: "Confirmed",
        props:['date'],
        methods:{
            tvalid(t){
                if (!t) return false;
                if (t=="") return false;
                if (t=="null") return false;
                return true
            }
        }
    }
</script>

<style scoped>
    img{ width:15px;}

</style>
