<template>
    <div>
        <div class="form-check">

            <Confirmed :date="akt.StepOneConfirmationTime" id="check1"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PLubrication.Příprava')}}</label>
        </div>

        <div class="form-check">

            <Confirmed :date="akt.StepTwoConfirmationTime" id="check2"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PLubrication.MazacíTuky')}}</label>
        </div>


        <div class="form-check">

            <Confirmed :date="akt.StepThreeConfirmationTime" id="check3"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PLubrication.ParametryMazání')}}</label>
        </div>

<div class="form-check">

            <Confirmed :date="akt.Step4ConfirmationTime" id="check4"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PLubrication.Dokončení')}}</label>
        </div>




        <div class="form-check">

            <Confirmed :date="akt.NoteConfirmationTime" id="check5"></Confirmed>
            <label class="form-check-label mx-2 my-1" >{{$t('m.PMaintance.Ověření')}}</label>
        </div>

        <div class="form-group ml-4 mt-3">
            <div class="form-control form-control-sm h-auto" id="poznamka" rows="5" v-if="akt.Note1" ><nl2br tag="div" :text="akt.Note1" ></nl2br> </div>
        </div>
    </div>
</template>

<script>import Confirmed from "./Confirmed";
    export default {
        name: "Lubrication",
        props:['akt'],
        components: {Confirmed},
        methods:{
            tvalid(t){
                if (!t) return false;
                if (t=="") return false;
                if (t=="null") return false;
                return true
            }
        }
    }
</script>

<style scoped>

</style>
